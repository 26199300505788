<template>
  <content-with-sidebar class="cws-container cws-sidebar-right blog-wrapper">

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
            :img-src="$options.filters.mediaUrl(item, 'avatar', 'base')"
            img-top
            img-alt="Blog Detail Pic"
            :title="$options.filters.transl(item, 'title')"
          >
            <div class="my-1 py-25">
              <b-link
                v-for="(tag,index) in item.categories"
                :key="index"
              >
                <b-badge
                  pill
                  class="mr-75"
                  variant="light-primary"
                >
                  {{ $options.filters.transl(tag, 'title') }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content"
              v-html="$options.filters.transl(item, 'description')"
            />
            <!-- eslint-enable -->
            <hr class="my-2">
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <Sidebar
        :blog-sidebar="blogSidebar"
        :categories="categories"
      />
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import GlobalMixin from '@mixins/GlobalMixin'
import router from '@/router'
import Sidebar from '@/views/blog/Sidebar'

export default {
  components: {
    ContentWithSidebar,
    Sidebar,
  },
  mixins: [GlobalMixin],
  metaInfo() {
    return {
      title: this.item ? `${this.$options.filters.transl(this.item, 'title')} | Блог | TeLeAds` : 'Блог | TeLeAds — біржа реклами в Телеграм',
    }
  },
  data() {
    return {
      item: null,
      socialShareIcons: [
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      blogSidebar: [],
      categories: [],
    }
  },
  watch: {
    '$route.params': {
      handler() {
        this.fetchPage()
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/promo/posts/popular')
      .then(response => {
        this.blogSidebar = response.data.data
      })

    await this.$http.get('/api/promo/categories', {
      params: {
        type: 'post',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categories = response.data.data
      })
  },
  methods: {
    async fetchPage() {
      await this.$http.get(`/api/promo/posts/${router.currentRoute.params.slug}`)
        .then(response => {
          const { data } = response.data
          this.item = data
        })
        .catch(error => {
          if (error.response.status === 404) {
            router.replace({ name: 'error-404' })
          }
        })
    },
  },
}
</script>
